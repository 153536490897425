import AnimatedButton from "../components/AnimatedButton";
import {ConfirmPopup, NotifyPopup} from "../components/Popups";
import {BgShape001, BgShape003} from "../components/BgShapes";
import React, {useContext, useRef, useState} from "react";
import {LoaderWithFetch, processResponse} from "../components/Loader";
import {useNavigate} from "react-router-dom";
import {Input, Tooltip} from "reactstrap";
import {ProfileData} from "../dao/ProfileData";
import {PageContext} from "../PageContext";

export default function ProfilePage() {
    return (
        <>
            <LoaderWithFetch<ProfileData> url={"/api/v1/profile"}>
                {(profileData, refresh) =>
                    (
                        <>
                            <UserInfoSection email={profileData.email} username={profileData.username}/>
                            <SetOpenAIKeySection openAiKey={profileData.openAiKey}
                                                 openAiOrganizationId={profileData.openAiOrganizationId}
                                                 openAiBaseUrl={profileData.openAiBaseUrl}
                                                 apiKeyActive={profileData.openAiKeyActive}
                                                 hasByokBusiness={profileData.hasByokBusiness}
                            />
                            <OptOutOfDataStorageSection optOutOfDataStorage={profileData.optOutOfDataStorage}
                                                        refresh={refresh}/>
                            <RequireFullAddressSection requireFullAddress={profileData.requireFullAddress}
                                                       refresh={refresh}/>
                            <DeleteAccountSection userId={profileData.userId} email={profileData.email}/>
                        </>
                    )
                }
            </LoaderWithFetch>
            <div className="bg-pattern-wrap centered"><BgShape001/></div>
        </>
    )
}

function UserInfoSection(props: { email: string, username: string }) {
    return (
        <div className="overflow-hidden">
            <div className="section bg-neutral-800 curve-bottom">
                <div className="container-sm">
                    <h1>Profile</h1>
                    <div className="simple-grid mt-3">
                        <div className="style-section-heading">
                            <div className="w-layout-grid row-md items-center">
                                <h4 className="font-title">Username</h4>
                            </div>
                        </div>
                        <div className="text-lg _52ch">{props.username}</div>
                        <div className="style-section-heading">
                            <div className="w-layout-grid row-md items-center">
                                <h4 className="font-title">Email</h4>
                            </div>
                        </div>
                        <div className="text-lg _52ch">{props.email}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OptOutOfDataStorageSection(props: { optOutOfDataStorage: boolean, refresh: () => void }) {
    const context = useContext(PageContext)

    const onClick = async () => {
        const formData = new URLSearchParams();
        formData.append("optOutDataStorage", props.optOutOfDataStorage ? "false" : "true");

        const response = await fetch("/api/v1/setOptOutOfDataStorage", {
            method: "POST",
            body: formData
        })
        await processResponse(response, () => {
            props.refresh()
        }, (errorKey, errorMessage) => {
            context.handleError(errorKey, "/profile", errorMessage);
        })
    }

    return (
        <div className="section">
            <div className="container-sm padding-md rounded-md bg-neutral-800">
                <BgShape003/>
                <div className="w-layout-grid column-base">
                    <div className="style-section-heading">
                        <div className="w-layout-grid row-md items-center">
                            <h4 className="font-title">Opt out of data storage</h4>
                        </div>
                    </div>
                    <div className="text-lg _52ch"><strong>Your code is never used to train AI models whether you
                        opt out of
                        data storage or not.<br/>‍<br/>‍</strong>Opting out of data storage means your interactions
                        with
                        Codebuddy, such as submitted code, will be removed after necessary processing. Opting out
                        may
                        lead to a reduced experience in support and troubleshooting as storing code is essential for
                        these services.<br/></div>
                    <div>
                        <div className="text-block-20">You are currently
                            opted {props.optOutOfDataStorage ? "out of" : "into"} data storage
                        </div>
                        <br/>
                        <AnimatedButton onClick={onClick}>
                            {props.optOutOfDataStorage ? "Opt In" : "Opt Out"}
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RequireFullAddressSection(props: { requireFullAddress: boolean, refresh: () => void }) {
    const context = useContext(PageContext)

    const onClick = async () => {
        const formData = new URLSearchParams();
        formData.append("requireFullAddress", props.requireFullAddress ? "false" : "true");

        const response = await fetch("/api/v1/setRequireFullAddress", {
            method: "POST",
            body: formData
        })
        await processResponse(response, () => {
            props.refresh()
        }, (errorKey, errorMessage) => {
            context.handleError(errorKey, "/profile", errorMessage);
        })
    }

    return (
        <div className="section">
            <div className="container-sm padding-md rounded-md bg-neutral-800">
                <BgShape003/>
                <div className="w-layout-grid column-base">
                    <div className="style-section-heading">
                        <div className="w-layout-grid row-md items-center">
                            <h4 className="font-title">Full address on invoice</h4>
                        </div>
                    </div>
                    <div className="text-lg _52ch">
                        We do not require your address. <br/>
                        <p>
                            However you might want your address on the invoices - for example so you can be reimbursed by your employer.<br/>
                        </p>
                        <p>
                            You can enable that here. It does not affect already existing invoices.
                            If you have an active subscription already, you can add your address via the Payment History button on the Plans page. It will take affect on the next invoice.
                        </p>
                    </div>
                    <div>
                        <div className="text-block-20">You are currently
                            opted {props.requireFullAddress ? "into" : "out of"} having the full address on the invoice
                        </div>
                        <br/>
                        <AnimatedButton onClick={onClick}>
                            {props.requireFullAddress ? "Opt Out" : "Opt In"}
                        </AnimatedButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DeleteAccountSection(props: { userId: number, email: string }) {
    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const onConfirm = async () => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append("confirmUserId", props.userId.toString());
        await fetch("/cmd?cmd=DeleteAccount", {method: "POST", body: urlSearchParams});
        navigate("/")
    }
    return (
        <section className="section">
            <div className="container-sm padding-md rounded-md bg-neutral-800">
                <BgShape001/>
                <div className="w-layout-grid column-base">
                    <div className="style-section-heading">
                        <div className="w-layout-grid row-md items-center">
                            <h4 className="font-title">Account Deletion</h4>
                        </div>
                    </div>
                    <div className="text-lg _52ch"><strong>Please be aware that pressing this button will
                        PERMANENTLY delete
                        your account. <br/>This process is not
                        reversible.</strong><br/>
                    </div>
                    <AnimatedButton className={"button-danger"} onClick={() => setOpen(true)}>Delete My
                        Account</AnimatedButton>
                    <ConfirmPopup open={open} setOpen={setOpen} onConfirm={onConfirm}>
                        Are you sure you want to delete your account?<br/>This action is PERMANENT.
                    </ConfirmPopup>
                </div>
            </div>
        </section>
    )
}

function SetOpenAIKeySection(props: {
    openAiKey: string,
    openAiOrganizationId: string,
    openAiBaseUrl: string,
    apiKeyActive: boolean,
    hasByokBusiness: boolean,
}) {
    const [apiKey, setApiKey] = useState<string>(props.openAiKey);
    const [organizationId, setOrganizationId] = useState<string>(props.openAiOrganizationId);
    const [baseUrl, setBaseUrl] = useState<string>(props.openAiBaseUrl);
    const [apiKeyActive, setApiKeyActive] = useState<boolean>(props.apiKeyActive);
    const [confirmPersonalOpen, setConfirmPersonalOpen] = useState<boolean>(false);
    const [openSaved, setOpenSaved] = useState<boolean>(false);
    const [openError, setOpenError] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const context = useContext(PageContext)

    const handleSubmitButton = async () => {
        if (apiKey.trim() === "" || !apiKeyActive || props.hasByokBusiness) {
            await sendAPIKey()
        } else {
            setConfirmPersonalOpen(true);
        }
    };

    const sendAPIKey = async () => {
        const formData = new URLSearchParams();
        formData.append("openAiKey", apiKey);
        formData.append("openAiOrganizationId", organizationId);
        formData.append("openAiBaseUrl", baseUrl);
        formData.append("apiKeyActive", apiKeyActive ? "true" : "false");
        const response = await fetch("/api/v1/setAPIKey", {
            method: "POST",
            body: formData
        });
        await processResponse(response, () => {
            setOpenSaved(true)
        }, (errorKey, errorMessage) => {
            if (errorKey === "invalid_api_key") {
                setOpenError(true);
                setError(errorMessage!!);
            } else {
                context.handleError(errorKey, "/profile", errorMessage);
            }
        })
    }

    return (
        <div id="set-api-key" className="section">
            <div className="container-sm padding-md rounded-md bg-neutral-800">
                <div className="style-section-heading">
                    <div>
                        <h4 className="font-title">OpenAI Key Information</h4>
                        <br/>
                        <p>
                            Here you can set the information for use by "Bring Your Own Key" (BYOK).
                        </p>
                    </div>
                </div>
                <div className="simple-grid mt-3">
                    <InputRow label="OpenAI Key"
                              placeholder="Enter your OpenAI API Key here (required)"
                              tooltip={"You can specify your OpenAI API key here. This key is required and the other fields are ignored if it is not set."}
                              value={apiKey} setValue={setApiKey}/>
                    <InputRow label="OpenAI Organisation ID"
                              placeholder="Enter your OpenAI Organisation ID here (optional)"
                              tooltip="(Optional) If you belong to multiple organizations, you can specify here which organization is used for the API requests."
                              value={organizationId} setValue={setOrganizationId}/>
                    <InputRow label="Azure Base URL"
                              placeholder="Enter your Azure Base URL here (optional)"
                              tooltip="(Optional) If you are using the Azure OpenAI Service, you can specify the base URL here."
                              value={baseUrl} setValue={setBaseUrl}/>
                    <div className="style-section-heading">
                        <div className="w-layout-grid row-md items-center">
                            <h5 className="font-title">Use This Key</h5>
                        </div>
                    </div>
                    <div className="text-lg _52ch">
                        <Input
                            type="switch"
                            role="switch"
                            defaultChecked={props.apiKeyActive}
                            onChange={(e) => setApiKeyActive(e.target.checked)}
                        />
                    </div>
                    <button onClick={handleSubmitButton} className="button-animated">
                        Submit
                    </button>
                </div>
            </div>
            <ConfirmPopup open={confirmPersonalOpen} setOpen={setConfirmPersonalOpen} onConfirm={sendAPIKey}
                          yesText="Confirm" noText="Cancel">
                I hereby confirm that I will only use this API key for personal projects.
            </ConfirmPopup>
            <NotifyPopup open={openSaved} setOpen={setOpenSaved}>
                {
                    apiKey.trim() === "" && (
                        <>
                            Your API key has been cleared.
                        </>
                    )
                }
                {
                    apiKey.trim() !== "" && (
                        <>
                            Your API key has been saved.&nbsp;
                            {
                                !apiKeyActive && (
                                    <>
                                        It is <strong>not active</strong>.
                                    </>
                                )
                            }
                            {
                                apiKeyActive && (
                                    <>
                                        It is <strong>active</strong>.
                                    </>
                                )
                            }
                        </>
                    )
                }
            </NotifyPopup>
            <NotifyPopup open={openError} setOpen={setOpenError} title="Error">
                An error occurred while saving your API key. This issue might be due to billing setup problems or exhausted OpenAI free credits.
                Note that API billing is separate from ChatGPT and must be configured independently.<br/>
                <br/>
                To resolve this, you will need to purchase additional credits from us or set up billing with OpenAI specifically for BYOK (Bring Your Own Key).<br/>
                <br/>
                Visit the <a className="link-light" href="https://platform.openai.com/settings/organization/billing/overview">OpenAI Billing Page</a><br/>
                <br/>
                For additional assistance, please contact us through the contact link at the bottom of the page.
                <br/><br/>
                The key has not been saved.<br/><br/>
                Error:<br/>
                <pre>{error}</pre>
            </NotifyPopup>
        </div>
    );
}

function InputRow(props: {
    label: string,
    placeholder: string,
    tooltip: string,
    value: string,
    setValue: (newValue: string) => void
}) {
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const infoRef = useRef(null)
    return <>
        <div className="style-section-heading">
            <div className="w-layout-grid row-md items-center">
                <h5 className="font-title" onClick={() => setTooltipOpen(true)} ref={infoRef}>{props.label}
                    &nbsp;<i className="fab fa-info-circle cursor-pointer"/>
                </h5>
            </div>
        </div>
        <div className="text-lg _52ch">
            <Input
                type="text"
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                style={{width: "100%"}}
                placeholder={props.placeholder}
                className="text-input"
            />
        </div>
        <Tooltip target={infoRef} isOpen={tooltipOpen} toggle={() => setTooltipOpen(false)} placement={"bottom"}>
            {props.tooltip}
        </Tooltip>
    </>;
}